.side-bar-area {
    background-color: var(--blue3);
    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.12), 2px 0 10px 0 rgba(0, 0, 0, 0.09);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 1;
}

.side-bar-area.active {
    left: 0;
    transition: all .5s ease;
}

.menu-icon {
    display: none;
}

@media(max-width: 768px) {
    .side-bar-area {
        left: -100%;
    }

    .menu-icon {
        display: flex;
        position: fixed;
        left: 20px;
        top: 20px;
        color: white;
        background-color: var(--blue3);
        border-radius: 8px;
        padding: 10px;
        z-index: 10;
    }
}