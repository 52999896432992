aside.logo {
    background-color: var(--blue3);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

aside.logo img {
    width: 70%;
}

@media(max-width: 768px) {
    aside.logo img {
        width: 50%;
    }
}