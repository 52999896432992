.portfolio {
    height: max-content;
    min-height: 100vh;
    width: calc(100vw - var(--aside-width) - 17px);
    background-color: var(--blue3);
    background-size: cover;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    overflow-x: hidden;
}

.portfolio h2 {
    color: var(--blue1);
    font-size: 3em;
    text-shadow: var(--shadow);
    margin-bottom: 0px;
    text-align: left;
}

.portfolio .carousel {
    padding-top: 20px;
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.portfolio .carousel::-webkit-scrollbar {
    display: none;
}

.portfolio .carousel .item {
    background-color: white;
    margin: 10px;
    padding: 20px 0px;
    width: 380px;
    border-radius: 10px;
    box-shadow: var(--shadow);
    flex: none;

    /* Display em grid */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 
        70px
        1fr 
        70px;
    grid-template-areas: 
        "title"
        "paragraph"
        "footer";
}


.portfolio .carousel .item .title {
    grid-area: title;
}

.portfolio .carousel .item .paragraph {
    grid-area: paragraph;
}

.portfolio .carousel .item .footer {
    grid-area: footer;
}

.portfolio .carousel .item .title {
    display: flex;
    flex-direction: row;
    align-items: center; 
    text-align: center;
    justify-content: space-around;
}

.portfolio .carousel .item .title h3 {
    font-size: 1.3em;
    color: var(--blue3);
}

.portfolio .carousel .item .title img {
    height: 70px;
}

.portfolio .carousel .item .paragraph {
    display: flex;
    flex-direction: row;
    align-items: center; 
    text-align: center;
}

.portfolio .carousel .item .paragraph p {
    text-align: justify;
    padding: 0px 25px;
    margin-bottom: 30px;
    font-size: 1.2em;
    color: var(--blue4);
}

.portfolio .carousel .item .technology {
    background-color: var(--blue2);
    text-align: right;
    width: 90px;
    padding: 4px 9px;
    margin-bottom: 15px;
    border-radius: 0px 20px 20px 0px;
    position: relative;
    right: 0;
}

.portfolio .carousel .item .technology img {
    width: 45px;
}

.portfolio .carousel .item .footer {
    display: flex;
    flex-direction: row; 
    text-align: center;
    align-items: center;
    justify-content: space-between;
}

.portfolio .carousel .item .footer a {
    text-decoration: none;
    cursor: pointer;
    background-color: var(--blue2);
    padding: 10px 20px;
    border-radius: 50px;
    color: white;
    height: 40px;
    align-items: center;
    margin: 0px 30px;
}

.portfolio .carousel .item .footer a:hover {
    background-color: var(--blue3);
}

.portfolio .arrow-mobile {
    display: none;
}

.portfolio .arrow-desktop {
    width: 100%;
    text-align: center;
}

.portfolio .arrow-desktop button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    margin: 0px 10px;
}

.portfolio .arrow-desktop button:hover {
    color: var(--blue2);
}

.portfolio .p-text {
    color: white;
    display: none;
}

@media(max-width: 768px) {
    .portfolio {
        width: 100vw;
    }

    .portfolio h2 {
        font-size: 2.7em;
    }

    .portfolio .carousel .item {
        width: 93%;
    }
    .portfolio .p-text {
        display: inherit;
    }

    .portfolio .arrow-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .portfolio .arrow-mobile i {
        color: white;
        margin: 0px 25px;
    }

    .portfolio .arrow-mobile .fa-angle-double-left {
        animation: go-left 1s infinite alternate;
    }
    
    @keyframes go-left {
        from {
          transform: translateX(0px);
        }
        to {
          transform: translateX(-10px);
        }
    }

    .portfolio .arrow-mobile .fa-angle-double-right {
        animation: go-right 1s infinite alternate;
    }
    
    @keyframes go-right {
        from {
          transform: translateX(0px);
        }
        to {
          transform: translateX(10px);
        }
    }

    .portfolio .arrow-desktop {
        display: none;
    }
}