.about {
    height: max-content;
    min-height: 100vh;
    width: calc(100vw - var(--aside-width) - 17px);
    background-color: var(--blue1);
    background-size: cover;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    overflow-x: hidden;
}

.about h2 {
    color: var(--blue3);
    font-size: 3em;
    text-shadow: var(--shadow);
    margin-bottom: 0px;
    text-align: left;
}

.about .p1 {
    display: flex;
    margin: 20px 0px;
}

.about .p1 img {
    width: 12em;
    margin: 30px;
    margin-top: 0px;
}

.about .p1 p {
    color: var(--blue4);
    font-size: 1.5em;
    text-align: justify;
}

.about .btn {
    align-items: center;
}

.about .btn a {
    text-decoration: none;
    color: white;
    background-color: var(--blue3);
    padding: 15px;
    font-size: 1.2em;
    border-radius: 15px;
}

.about .btn a:hover {
    background-color: var(--blue2);
}

.about .btn a i {
    padding-right: 10px;
}

.about h3 {
    color: var(--blue3);
    font-size: 1.8em;
    text-shadow: var(--shadow);
    margin-bottom: 2%;
    text-align: left;
    padding-left: 10px;
}

.about .photo img {
    border-radius: 50%;
    border: 7px solid var(--blue3);
}

.technologies img {
    height: 60px;
    padding: 0px 20px;
    margin: 10px 10px;
}

.technologies svg {
    color: var(--blue4);
    height: 45px;
    padding: 0px 20px;
}

@media(max-width: 768px) {
    .about {
        width: 100vw;
    }

    .about h2 {
        font-size: 2.7em;
    }

    .about .p1 {
        flex-direction: column;
    }

    .technologies img {
        margin: 5px 0px;
    }
}