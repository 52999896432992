.contact {
    height: max-content;
    min-height: 100vh;
    width: calc(100vw - var(--aside-width) - 17px);
    background-color: var(--blue1);
    background-size: cover;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    overflow-x: hidden;
}

.contact h2 {
    color: var(--blue3);
    font-size: 3em;
    text-shadow: var(--shadow);
    text-align: left;
}

.contact .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "form icons";
}

.contact .content .form {
    grid-area: form;
}

.contact .content .icons-desktop {
    grid-area: icons;
}

.contact .content .form form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
}

.contact .content .form .feedback-input {
    color:var(--blue4);
    font-weight:500;
    font-size: 18px;
    border-radius: 5px;
    line-height: 22px;
    background-color: transparent;
    border:2px solid var(--blue3);
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width:80%;
    box-sizing: border-box;
    outline:0;
}
  
.contact .content .form .feedback-input:focus { 
    border:2px solid var(--blue4); 
}
  
.contact .content .form textarea {
    height: 150px;
    line-height: 150%;
    resize:vertical;
}
  
.contact .content .form [type="submit"] {
    width: 80%;
    background: var(--blue3);
    border-radius:5px;
    border:0;
    cursor:pointer;
    color:white;
    font-size:24px;
    padding-top:10px;
    padding-bottom:10px;
    transition: all 0.3s;
    font-weight:700;
}
  
.contact .content .form [type="submit"]:hover {
    background: var(--blue2);
}

.contact .content .icons-mobile {
    display: none;
}

.contact .content .icons-desktop .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.contact .content .icons-desktop .item i {
    margin-right: 20px;
    color: var(--blue3);
}

.contact .content .icons-desktop .item p {
    font-size: 1.6em;
    color: var(--blue4);
}

@media(max-width: 768px) {
    .contact {
        width: 100vw;
    }

    .contact h2 {
        font-size: 2.7em;
    }

    .contact .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact .content .form form {
        margin-bottom: 20px;
        align-items: center;
    }

    .contact .content .icons-desktop {
        display: none;
    }

    .contact .content .icons-mobile {
        display: flex;
        flex-direction: row;
    }

    .contact .content .icons-mobile a {
        margin: 20px 10px;
        padding: 15px;
        background-color: var(--blue3);
        border-radius: 70px;
        color: white;
    }
}