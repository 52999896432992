:root {
    --blue1: #d0e1f9;
    --blue2: #4d648d;
    --blue3: #283655;
    --blue4: #1e1f26; 
    --logo-height: 100px;
    --header-height: 100px;
    --menu-top-height: 70px;
    --aside-width: 225px;
    --footer-height: 40px;

    --shadow:
        0 2px 23px 0 rgba(0, 0, 0, 0.1),
        0 2px 49px 0 rgba(0, 0, 0, 0.06);
}

* {
    box-sizing: border-box;
    font-family: 'Readex Pro', 'sans-serif';
}

body {
    background-color: var(--blue1);
}

html {
    overflow-y: scroll;
    scroll-behavior: smooth;
}

/* Layot em Grid */

.app {
    margin: 0px;
    display: grid;
    grid-template-columns: var(--aside-width)  1fr;
    grid-template-rows: 
        /* var(--header-height)  */
        1fr; 
        /* var(--footer-height); */
    grid-template-areas: 
        /* "logo content" */
        "menu content";
        /* "menu footer"; */
    height: 100vh;
    /* width: 100vw; */
    background-color: #F5F5F5;
}

aside.logo {
    grid-area: logo;
}

header.header {
    grid-area: header;
}

aside.side-bar-area {
    grid-area: menu;
}

main.content {
    grid-area: content;
}

footer.footer {
    grid-area: footer;
}

@media(max-width: 768px) {
    .app {
        grid-template-rows: 
            1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
            "content"
    }
}
  
/* @media(max-width: 576px) {
    .app {
        grid-template-rows:
            var(--logo-height)
            var(--menu-top-height)
            1fr
            var(--footer-height);
        grid-template-columns: 1fr;
        grid-template-areas: 
            "logo"
            "menu"
            "content"
            "footer";
    }
}  */