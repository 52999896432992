.home {
    height: max-content;
    min-height: 100vh;
    width: calc(100vw - var(--aside-width) - 17px);
    background:
    linear-gradient(
      rgba(40,54,85,.9),rgba(40,54,85,.9)
    ),
    url(../../assets/imgs/background.jpg) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
}

.home h1 {
    color: var(--blue1);
    font-size: 5em;
}

.home h2 {
    color: white;
    font-family: 'Share Tech Mono', monospace;
    font-weight: 50;
    font-size: 1.6em;
    text-shadow: var(--shadow);
}

.arrow {
    padding-top: 15%;
}

.arrow-text {
    color: white;
    font-size: 1em;
}

.arrow .arrow-icon {
    font-size: 1.4em;
    color: white;
    opacity: 1;
    animation: anima 1500ms ease infinite;
}
@keyframes anima {
    to {
        opacity: 0.1;
        transform: scale(0.85) translateY(20px);
    }
}

@media(max-width: 768px) {
    .home {
      width: 100vw;
      background-size: cover;
      background:
      linear-gradient(
        rgba(40,54,85,.9),rgba(40,54,85,.9)
      ),
      url(../../assets/imgs/background-mobile.jpg) no-repeat;
    }
}