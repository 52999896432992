.graduations {
    height: max-content;
    min-height: 100vh;
    width: calc(100vw - var(--aside-width) - 17px);
    background-color: var(--blue1);
    background-size: cover;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    overflow-x: hidden;
}

.graduations h2 {
    color: var(--blue3);
    font-size: 3em;
    text-shadow: var(--shadow);
    margin-bottom: 0px;
    text-align: left;
}

.graduations h3 {
    color: var(--blue3);
    font-size: 1.8em;
    text-shadow: var(--shadow);
    text-align: left;
    padding-left: 30px;
}

.graduations .rb-container {
    text-align: left;
}

.graduations .rb-container ul.rb {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.graduations .rb-container ul.rb li {
  list-style: none;
  margin: auto;
  margin-left: 10em;
  min-height: 50px;
  border-left: 1px dashed var(--blue3);
  padding: 0 0 50px 30px;
  position: relative;
}

.graduations .rb-container ul.rb li:last-child {
  border-left: 0;
}

.graduations .rb-container ul.rb li::before {
  position: absolute;
  left: -18px;
  top: -5px;
  content: " ";
  border: 8px solid var(--blue3);
  border-radius: 500%;
  background: var(--blue1);
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

.graduations .rb-container ul.rb li .timestamp {
  text-align: left;
  color: var(--blue4);
  position: relative;
  font-size: 1em;
  font-weight: 600;
}

.graduations .rb-container ul.rb li .item-title {
  text-align: left;
  color: var(--blue3);
  font-size: 1.4em;
  font-weight: 700;
}

.graduations .rb-container .rb .rb-item .item-text {
    color: var(--blue3);
    font-weight: 600;
}

@media(max-width: 768px) {
  .graduations {
    width: 100vw;
  }

  .graduations h2 {
    font-size: 2.7em;
  }

  .graduations .rb-container ul.rb li {
    margin-left: 30px;
  }
}