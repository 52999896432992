.experiences {
    height: max-content;
    min-height: 100vh;
    width: calc(100vw - var(--aside-width) - 17px);
    background-color: var(--blue3);
    background-size: cover;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    overflow-x: hidden;
}

.experiences h2 {
    color: var(--blue1);
    font-size: 3em;
    text-shadow: var(--shadow);
    text-align: left;
}

.experiences .rb-container {
    text-align: left;
}

.experiences .rb-container ul.rb {
  margin: 2.5em 0;
  padding: 0;
  display: inline-block;
}

.experiences .rb-container ul.rb li {
  list-style: none;
  margin: auto;
  margin-left: 10em;
  min-height: 50px;
  border-left: 1px dashed #fff;
  padding: 0 0 50px 30px;
  position: relative;
}

.experiences .rb-container ul.rb li:last-child {
  border-left: 0;
}

.experiences .rb-container ul.rb li::before {
  position: absolute;
  left: -18px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 1);
  border-radius: 500%;
  background: var(--blue3);
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

.experiences .rb-container ul.rb li .timestamp {
  text-align: left;
  color: var(--blue1);
  position: relative;
  font-size: 1em;
}

.experiences .rb-container ul.rb li .item-title {
  text-align: left;
  color: #fff;
  font-size: 1.4em;
}

.experiences .rb-container .rb .rb-item .item-text {
    color: #fff;
}

@media(max-width: 768px) {
  .experiences {
    width: 100vw;
  }

  .experiences h2 {
    font-size: 2.7em;
  }

  .experiences .rb-container ul.rb li {
    margin-left: 30px;
  }
}